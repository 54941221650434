<template>
  <div style="padding: 20px">
    <div class="qkxx_title">期刊信息设置</div>
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      label-position="left"
      size="mini"
    >
      <div>
        <div class="part_title">封面更新</div>
        <div>
          <el-form-item label="上传封面">
            <label for="headImg">
              <img class="photo" :src="form.headImgUrl" alt />
            </label>
            <input
              class="file"
              style="display: none"
              id="headImg"
              name="multipartFile"
              type="file"
              @change="up1"
            />
            <span style="color: #999; margin-left: 10px"
              >说明：系统只保存和显示最新的封面</span
            >
          </el-form-item>
        </div>
      </div>
      <div>
        <div class="part_title">
          期刊通知<span>（此部分将作为期刊的刊发通知告知作者）</span>
        </div>
        <div>
          <el-form-item label="期次">
            <el-input
              v-model="form.name"
              placeholder="请输入期次"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="截稿时间">
            <el-col :span="6">
              <el-date-picker
                v-model="form.year"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="预计出刊时间">
            <el-col :span="6">
              <el-date-picker
                v-model="form.years"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div>
      <div class="part_title">期刊信息</div>
      <div>
        <el-form
          :inline="true"
          :model="form"
          label-width="100px"
          label-position="left"
          class="demo-form-inline"
          size="mini"
        >
          <el-form-item label="国内刊号">
            <el-input
              v-model="form.user"
              placeholder="请输入国内刊号"
            ></el-input>
          </el-form-item>
          <el-form-item label="国际刊号">
            <el-input
              v-model="form.issn"
              placeholder="请输入国际刊号"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮政编码">
            <el-input
              v-model="form.postalcode"
              placeholder="请输入邮政编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="期刊主编">
            <el-input
              v-model="form.qikaneditor"
              placeholder="请输入期刊主编"
            ></el-input>
          </el-form-item>
          <el-form-item label="审稿时间">
            <!-- <el-select
                 v-model="form.checktimes"
                 clearable
                 placeholder="请选择期刊类别"
                 style="width: 180px"
               >
                 <el-option
                   v-for="item in checktimesList"
                   :key="item.time"
                   :label="item.time"
                   :value="item.time"
                 ></el-option>
               </el-select>-->
               <el-input
                       v-model="form.checktimes"
                       placeholder="请输入审稿时间"
               ></el-input>
          </el-form-item>
          <el-form-item label="期刊级别">
            <el-select
              v-model="form.qikanlevel"
              clearable
              placeholder="请输入期刊级别"
              style="width: 180px"
            >
              <el-option
                v-for="item in magzinelevel"
                :key="item.level"
                :label="item.level"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮发代号">
            <el-input
              v-model="form.postnumber"
              placeholder="请输入期刊主编"
            ></el-input>
          </el-form-item>
          <el-form-item label="创刊时间">
            <el-input
              v-model="form.createtimes"
              placeholder="请输入创刊时间"
            ></el-input>
          </el-form-item>
          <el-form-item label="期刊语言">
            <el-input
              v-model="form.qikanlanguage"
              placeholder="请输入期刊语言"
            ></el-input>
          </el-form-item>
          <el-form-item label="发行地点">
            <el-input
              v-model="form.publishlocation"
              placeholder="请输入发行地点"
            ></el-input>
          </el-form-item>
          <el-form-item label="出版周期">
            <el-select
              v-model="form.publishcycle"
              placeholder="请选择出版周期"
              style="width: 180px"
            >
              <el-option
                v-for="item in tested"
                :key="item.worldtest"
                :label="item.worldtest"
                :value="item.worldtest"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期刊类别">
            <el-select
              v-model="form.qikantype"
              clearable
              placeholder="请选择期刊类别"
              style="width: 180px"
            >
              <el-option
                v-for="item in bytesUploaded"
                :key="item.id"
                :label="item.types"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="left"
          size="mini"
        >
          <el-form-item label="主管单位">
            <el-input
              v-model="form.competentunit"
              placeholder="请输入主管单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="主办单位">
            <el-input
              v-model="form.sponsor"
              placeholder="请输入主办单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="期刊收录">
            <el-select clearable multiple v-model="form.qikaninclude">
              <el-option
                v-for="item in fileUpload"
                :key="item.number"
                :label="item.include"
                :value="item.number"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期刊荣誉">
            <el-input
              v-model="form.qikanhonour"
              placeholder="请输入期刊荣誉"
            ></el-input>
          </el-form-item>

          <el-form-item label="杂志栏目">
            <el-input
              id="textarea"
              v-model="form.journal"
              type="textarea"
              placeholder="请输入杂志栏目"
            ></el-input>
          </el-form-item>
          <el-form-item label="期刊简介">
            <el-input
              id="textarea"
              v-model="form.jianjie"
              type="textarea"
              placeholder="请输入期刊简介"
            ></el-input>
          </el-form-item>
          <el-form-item label="投稿要求">
            <el-input
              id="textarea"
              v-model="form.tougaoyaoqiu"
              type="textarea"
              placeholder="请输入投稿要求"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" size="big" @click="inFormaTion"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import addImg from "../../../assets/image/upImg.jpg";
import { information, Uploadupload, savelogininformation } from '@/API/QKD/uploaded'
export default {
  data () {
    return {
      imageUrl: '',
      form: {
        name: '',
        region: '',
        year: '',
        month: '',
        date: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        user: '',// 国内刊号
        issn: '',// 国际刊号
        qikaneditor: '', // 期刊主编
        checktimes: '',// 审稿时间
        postalcode: '', // 邮政编码
        headImg: '', // 上传头像
        qikanlevel: '',// 期刊类别
        headImgUrl: addImg,//回显头像
        years: '', // 截稿时间
        months: '',
        dates: '',
        createtimes: '',
        postnumber: '', //邮编代号
        qikanlanguage: '',//期刊语言
        publishlocation: '', // 发行地点
        publishcycle: '', // 出版周期
        qikantype: '', // 期刊类别
        competentunit: '',// 主管单位
        sponsor: '', // 主办单位
        qikaninclude: [],// 期刊收录
        qikanhonour: '',// 期刊荣耀
        qikanintroduction: '',// 期刊简介
        journal: '',// 杂志专栏
        jianjie: '',//期刊简介
        tougaoyaoqiu: '',

      },
      bytesUploaded: [],// 期刊分类
      fileUpload: "", // 期刊收录
      tested: [
        {
          id: 0, worldtest: '不限'
        },
        { id: 1, worldtest: '周二刊' },
        {
          id: 2, worldtest: '周刊'
        },
        {
          id: 3, worldtest: '半月刊'
        },
        {
          id: 4, worldtest: '月刊'
        },
        {
          id: 5, worldtest: '双月刊'
        },
        {
          id: 6, worldtest: '季刊'
        },
        {
          id: 7, worldtest: '半年刊'
        },
        {
          id: 8, worldtest: '年刊'
        },
        {
          id: 9, worldtest: '旬刊'
        },
      ],// 出版周期
      checktimesList: [
        { time: '1个月以内' },
        { time: '1-3月' },
        { time: '3-6月' },
        { time: '6-9月' },
        { time: '6-12月内' },
      ],// 期刊级别
      magzinelevel: [
        { level: 'CSSCI南大核心期刊', 'code': 1 },
        { level: '北大核心期刊', 'code': 2 },
        { level: 'CSCD核心期刊', 'code': 3 },
        { level: '统计源期刊', 'code': 4 },
        { level: '国家级期刊', 'code': 5 },
        { level: '省级期刊', 'code': 6 },
        { level: 'SCI核心期刊', 'code': 7 },
      ],// 审稿时间
    };
  },
  mounted () {
    this.mLinkUpload()
    this.registerupload()
    this.informations()
  },
  methods: {
    up1 (e) {
      //头像
      this.form.headImg = e.target.files[0]; //图片文件
      this.form.headImgUrl = URL.createObjectURL(e.target.files[0]); //图片路径回显

    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    inFormaTion () {
      let id = localStorage.getItem('QKId')
      var formdata = new FormData()
      formdata.append('id', id);
      formdata.append('cover', this.form.headImg);
      formdata.append('issue', this.form.name);
      formdata.append('deadline', this.form.year);
      formdata.append('publishtime', this.form.years);
      formdata.append('guoneikanhao', this.form.user);
      formdata.append('issn', this.form.issn);
      formdata.append('postalcode', this.form.postalcode);
      formdata.append('qikaneditor', this.form.qikaneditor);
      formdata.append('checktime', this.form.checktimes);
      formdata.append('qikanlevel', this.form.qikanlevel);
      formdata.append('postnumber', this.form.postnumber);
      formdata.append('createtimes', this.form.createtimes);
      formdata.append('qikanlanguage', this.form.qikanlanguage);
      formdata.append('publishlocation', this.form.publishlocation);
      formdata.append('publishcycle', this.form.publishcycle);
      formdata.append('qikantype_id', this.form.qikantype);
      formdata.append('competentunit', this.form.competentunit);
      formdata.append('sponsor', this.form.sponsor);
      formdata.append('qikaninclude_number', this.form.qikaninclude.join());
      formdata.append('qikanhonour', this.form.qikanhonour);
      formdata.append('journal', this.form.journal);
      formdata.append('qikanintroduction', this.form.jianjie);
      formdata.append('requirement', this.form.tougaoyaoqiu);
      //添加
      this.$axios({
        method: "post",
        url: "/qikan/SettingJournalInformation",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(res => {
        if (res.data.code == 0) {
          this.$message({
            type: 'info',
            message: '保存成功',
            type: 'success'
          });
          this.mLinkUpload()

        } else {
          this.$message(res.data.msg);
        }

      })
    },
    registerupload () {
      Uploadupload().then(res => {
        
        this.bytesUploaded = res.data.data
        console.log(this.bytesUploaded)
      })
    },
    informations () {
      information().then(res => {
        this.fileUpload = res.data.data
        console.log(this.fileUpload)
      })
    },
    // 回显期刊信息
    mLinkUpload () {
      let id = localStorage.getItem('QKId')
      savelogininformation(id).then(res => {
        this.form.headImgUrl = this.URL + res.data.data.cover
        this.form.name = res.data.data.issue
        this.form.year = res.data.data.deadline
        this.form.years = res.data.data.publishtime
        this.form.user = res.data.data.guoneikanhao
        this.form.issn = res.data.data.issn
        this.form.postalcode = res.data.data.postalcode
        this.form.qikaneditor = res.data.data.qikaneditor
        this.form.checktimes = res.data.data.checktime
        this.form.qikanlevel = res.data.data.qikanlevel
        this.form.postnumber = res.data.data.postnumber
        this.form.createtimes = res.data.data.createtime
        this.form.qikanlanguage = res.data.data.qikanlanguage
        this.form.publishlocation = res.data.data.publishlocation
        this.form.publishcycle = res.data.data.publishcycle
        this.form.qikantype = res.data.data.qikantype_id
        this.form.competentunit = res.data.data.competentunit
        this.form.sponsor = res.data.data.sponsor
        this.form.qikaninclude = res.data.data.qikaninclude_number.split(',')
        this.form.jianjie = res.data.data.qikanintroduction
        this.form.tougaoyaoqiu = res.data.data.requirement
        for (var i = 0; i < this.form.qikaninclude.length; i++) {
          this.form.qikaninclude[i] = parseInt(this.form.qikaninclude[i])
        }
        this.form.qikanhonour = res.data.data.qikanhonour
        this.form.journal = res.data.data.journal
      })
    }

  }
}
</script>

<style>
#textarea {
  resize: none;
}

.qkxx_title {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e6e6e6;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}

.avatar {
  width: 130px;
  height: 130px;
  display: block;
}

.part_title {
  height: 80px;
  line-height: 80px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  box-sizing: border-box;
}

.part_title span {
  color: #999;
  font-size: 14px;
}
.photo {
  width: 150px !important;
  height: 150px !important;
}
</style>