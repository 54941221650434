import request from '@/utils/request'
import qs from 'qs'
// 期刊收录
export function information () {
  
  return request({
    url: '/home/Include',
    method: 'post',
  
  })
}
// 期刊分类
export function Uploadupload () {
  let data = {
  
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Academic',
    method: 'post',
    data
  })
}

//回显信息
// 查询个人资料
export function savelogininformation (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/QueryJournalInformation',
    method: 'post',
    data
  })
}

